<template>
  <div class="has-border-radius p-1 mb-8 columns">
    <div class="column is-12">
      <p>
        <span class="font-light has-text-grey-light text-md ml-2">
          {{ status.updated_at | moment("DD-MM-YY h:mm a") }}</span
        >
      </p>
      <p>
        <span class="has-text-primary underline font-medium">{{
          status.persona.nombre_completo.split("|")[0]
        }}</span>
        Realizó un cambio de status a
        <b>{{ status.status.nombre }}</b>
      </p>
      <p><b>Motivo:</b> {{ status.motivo }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CambioStatusCard",
  props: {
    status: Object
  }
};
</script>
