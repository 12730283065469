<template>
  <div class="columns text-left is-multiline mb-4 " :class="{ 'ml-1': !small }">
    <div class="column is-12 py-0" v-if="!small">
      <div class="text-right">
        <b-button
          class="mb-4"
          icon-left="plus"
          :type="pasiva ? 'is-pasivo' : arrendamiento ? 'is-arrendamiento' : 'is-primary'"
          @click="toggleCapvitalFiles"
        >
          Subir o ligar documentos
        </b-button>
      </div>
      <bienvenido-card
        :pasiva="pasiva"
        class="mb-6"
        v-if="getMe"
        :titulo="'Organiza y sube documentos ' + texto.banner"
        descripcion="Esta sección está diseñada para que todos los documentos de una
            disposición estén en un solo lugar. Sube cualquier documento desde
            tu computadora o Capvital Files."
        :img="
          getMe.genero
            ? getMe.genero.id == 1
              ? '/images/files_man.svg'
              : '/images/files_woman.svg'
            : '/images/files_man.svg'
        "
        img-class="w-2/3"
      />
    </div>
    <div class="text-3xl font-bold has-text-grey-darker" v-if="!small">
      Documentos
    </div>
    <b-button
      v-if="small"
      icon-left="plus"
      class="mt-8 mx-3"
      expanded
      :outlined="outlinedBtn"
      type="is-primary"
      @click="toggleCapvitalFiles"
      >Subir o ligar archivos</b-button
    >
    <div class="column is-12 pb-0 px-0" v-for="doc in documentos" :key="doc.id">
      <documento-card :doc="doc" @desligar="desligarDocumento" :small="small" :esRequisito="tipo == 'requisitos_supervision'" :archivosObligatorios="archivosObligatorios" :requisito_id="requisito_id" />
    </div>
    <div class="column is-12 px-0" v-if="documentos.length == 0">
      <div
        class="has-background-white-ter has-border-radius p-10 h-80"
        v-if="!small"
      >
        <empty-state
          imagen="/images/empty_state_files.png"
          :titulo="'No tienes documentos en ' + texto.emptystate"
          subtitulo="Sube un documento en el botón de arriba"
          :tituloClass="'title is-4'"
          :subtituloClass="'subtitle is-5'"
        />
      </div>
      <div v-else class="has-background-white-ter p-3 has-border-radius">
        <empty-state
          :titulo="'No tienes documentos en ' + texto.emptystate"
          subtitulo="Sube un documento en el botón de arriba"
          :tituloClass="'title is-4'"
          :subtituloClass="'subtitle is-5'"
          :small="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DocumentoCard from "@/components/Files/DocumentoCard";
import BienvenidoCard from "@/components/cards/BienvenidoCard";
import EmptyState from "@/components/generals/EmptyState";

export default {
  name: "Documentos",
  components: {
    DocumentoCard,
    BienvenidoCard,
    EmptyState
  },
  props: {
    pasiva: Boolean,
    documentos: Array,
    tipo: String,
    arrendamiento: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    outlinedBtn: {
      type: Boolean,
      default: false
    },
    archivosObligatorios: {
      type: Boolean,
      default: false,
    },
    requisito_id: Number,
  },
  computed: {
    ...mapGetters(["getMe"]),
    texto() {
      switch (this.tipo) {
        case "disposicion":
          return {
            banner: "a la disposición",
            emptystate: "esta disposición"
          };
        case "pago":
          return {
            banner: "al pago",
            emptystate: "este pago"
          };
        case "requisitos_supervision":
          return {
            banner: "al requisito",
            emptystate: "este requisito"
          };
        case "garantias_disposicion":
          return {
            banner: "en la garantia",
            emptystate: "la garantía a crear"
          };
        case "garantias_linea":
          return {
            banner: "en la garantia",
            emptystate: "la garantía a crear"
          };
        case "linea":
          return {
            banner: "en la línea de crédito",
            emptystate: "la línea de crédito"
          };
        case "activo":
          return {
            banner: "en al activo",
            emptystate: "este activo"
          };
        default:
          return "";
      }
    }
  },
  methods: {
    desligarDocumento(id) {
      this.$emit("desligar", id);
    },
    // Activa Capvital Files
    toggleCapvitalFiles() {
      this.$emit("togglecf");
    }
  }
};
</script>
