<template>
  <div
    class="has-border-radius p-2"
    :class="{
      'has-background-white': white == true,
      'has-background-white-ter': white == false
    }"
  >
    <div class="columns is-mobile shadow-capvital has-border-radius">
      <div class="column is-10 is-11-desktop">
        <editable v-model="comentario" />
        <div v-if="tagging">
          <div
            class="card has-border-radius shadow-none p-2 h-32 overflow-y-auto"
            v-if="personasFiltered.length > 0"
          >
            <ul>
              <li
                v-for="persona in personasFiltered"
                :key="persona.personas_id"
                class="cursor-pointer select-persona has-border-radius px-2 py-1"
                @click="setPersona(persona)"
              >
                {{ persona.alias }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="column text-center cursor-pointer has-border-radius flex items-center justify-center"
        :class="{
          'button-comentario': small,
          'button-comentario-large': !small
        }"
        @click="enviarComentario"
      >
        <b-icon
          :class="{ 'has-text-primary': active, 'has-text-grey': !active }"
          icon="paper-plane"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Editable from "@/components/form/Editable";
export default {
  name: "ComentarioInput",
  components: {
    Editable
  },
  data() {
    return {
      comentario: null,
      active: false,
      tagging: false,
      tagtext: ""
    };
  },
  props: {
    personas: Array,
    taggedpersonas: Array,
    white: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    //Se agrega la persona a las personas taggeadas
    setPersona(persona) {
      this.taggedpersonas.push(persona);
      this.comentario = this.comentario.split("@")[0];
      this.comentario +=
        '<b contenteditable="false" class="has-text-primary">' +
        persona.alias.trim() +
        "</b>";
      this.tagging = false;
      this.tagtext = "";
    },
    //Se envia comentario al parent, para que este lo envie a la API
    enviarComentario() {
      if (!this.active) {
        return;
      }
      this.taggedpersonas.forEach((persona, index, obj) => {
        let str = `<b contenteditable="false" class="has-text-primary">${persona.alias.trim()}</b>`;
        if (!this.comentario.includes(str)) {
          obj.splice(index, 1);
        }
      });
      this.comentario = this.comentario.replace("&nbsp;", " ");
      this.comentario = this.comentario.replace('contenteditable="false"', "");
      this.comentario.replace("  ", " ");
      let data = {
        personas_nombradas: this.taggedpersonas,
        comentario: this.comentario
      };
      this.$emit("comentar", data);
      this.comentario = null;
      this.active = false;
      this.tagging = false;
    }
  },
  computed: {
    personasFiltered() {
      if (this.tagtext.length > 2) {
        return this.personas.filter(
          option =>
            option.alias
              .toString()
              .toLowerCase()
              .indexOf(this.tagtext.toString().toLowerCase()) >= 0
        );
      }
      return [];
    }
  },
  watch: {
    comentario: function(val, oldval) {
      if (val == null) {
        return;
      }
      if (oldval == null && val.includes("@")) {
        this.tagging = true;
      } else if (val.includes(" @") && !oldval.includes("@")) {
        this.tagging = true;
      }
      if (val.includes("@")) {
        this.tagtext = val.split("@")[1];
      } else {
        this.tagging = false;
      }
      if (val != null && val.length > 0) {
        this.active = true;
      } else {
        this.active = false;
      }
    }
  }
};
</script>
