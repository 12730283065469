<template>
  <div class="columns is-multiline">
    <div class="column is-12 text-left" v-if="small">
      <comentario-input
        :personas="getEjecutivos"
        :small="small"
        :taggedpersonas="taggedpersonas"
        @comentar="enviarComentario"
      />
    </div>
    <div class="column is-12 comments__content overflow-y-auto">
      <div class="h-full" v-if="comentarios != null && comentarios.length == 0">
        <empty-state
          imagen="/images/empty_state_bitacora.png"
          imgclass="w-1/3"
          :titulo="emptyStateText"
          subtitulo="Introduce un comentario abajo."
          :vertical="true"
          :small="small"
        />
      </div>
      <div v-else>
        <transition-group class="w-full" name="fadeInOut" mode="out-in">
          <div v-for="entrada in comentariosOrdered" :key="entrada.id">
            {{ comentario }}
            <cambio-status-card :status="entrada" v-if="entrada.status_id" />
            <comentario-card
              v-else
              :comentario="entrada"
              :personas="getEjecutivos"
              @responder="enviarComentario"
              :small="small"
              :no-bg="noBg"
            />
          </div>
        </transition-group>
      </div>
    </div>
    <div class="column is-12 text-left" v-if="!small">
      <comentario-input
        :personas="getEjecutivos"
        :taggedpersonas="taggedpersonas"
        @comentar="enviarComentario"
      />
    </div>
  </div>
</template>

<script>
import EmptyState from "@/components/generals/EmptyState";
import ComentarioCard from "@/components/comentarios/ComentarioCard";
import ComentarioInput from "@/components/comentarios/ComentarioInput";
import CambioStatusCard from "@/components/comentarios/CambioStatusCard";
import { mapGetters } from "vuex";

export default {
  name: "Comentarios",
  components: {
    EmptyState,
    ComentarioCard,
    ComentarioInput,
    CambioStatusCard
  },
  props: {
    comentarios: Array,
    tipo: String,
    small: {
      type: Boolean,
      default: false
    },
    noBg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      comentario: null,
      active: false,
      tagging: false,
      tagtext: "",
      taggedpersonas: []
    };
  },
  methods: {
    enviarComentario(data) {
      data.personas_nombradas = [...new Set(data.personas_nombradas)];
      this.$emit("comentar", data);
      this.taggedpersonas = [];
    }
  },
  watch: {
    comentario: function(val, oldval) {
      if (val == null) {
        return;
      }
      if (oldval == null && val.includes("@")) {
        this.tagging = true;
      } else if (val.includes(" @") && !oldval.includes("@")) {
        this.tagging = true;
      }
      if (val.includes("@")) {
        this.tagtext = val.split("@")[1];
      } else {
        this.tagging = false;
      }
      if (val != null && val.length > 0) {
        this.active = true;
      } else {
        this.active = false;
      }
    }
  },
  computed: {
    emptyStateText() {
      switch (this.tipo) {
        case "disposicion":
          return "No hay comentarios en esta disposición";
        case "pago":
          return "No hay comentarios en este pago";
        case "requisito":
          return "No hay comentarios en este requisito";
        default:
          return "No hay comentarios";
      }
    },
    comentariosOrdered() {
      return this.small ? this.comentarios.slice().reverse() : this.comentarios;
    },
    ...mapGetters("catalogos", ["getEjecutivos"])
  },
  mounted() {
    if (this.getEjecutivos == null) {
      this.$store.dispatch("catalogos/getEjecutivos");
    }
    if (
      this.comentarios != null &&
      this.comentarios.length > 0 &&
      !this.small
    ) {
      setTimeout(() => {
        document
          .querySelectorAll("span.w-full > div")
          [
            document.querySelectorAll("span.w-full > div").length - 1
          ].scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }
};
</script>

<style lang="scss">
.comments__content {
  height: 600px;
}
.button-comentario {
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: var(--grey-lighter);
  }
}
.button-comentario-large {
  padding: 0.5rem;
  border: 4px white solid;
  border-left: none;
  border-radius: 0 9px 9px 0;
  background-color: var(--grey-lighter);
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #cecece;
  }
}
li.select-persona {
  &:hover {
    background-color: var(--grey-lighter);
  }
}

@media only screen and (max-width: 600px) {
  .comments__content {
    height: 400px;
  }
}
</style>
