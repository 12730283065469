<template>
  <div
    class="has-border-radius p-4 mb-8 columns"
    :class="{ 'has-background-white-ter': !noBg }"
    v-if="checked && comentario.comment"
  >
    <div class="column is-1 px-0 ml-0 text-left" v-if="!small">
      <icon-initials
        direction="ml-0"
        :nombre="comentario.comment.author.nombre_completo.split('|')[0]"
      />
    </div>
    <div
      class="column is-11 text-left"
      :class="{ 'is-11': !small, 'is-12': small }"
    >
      <p class="font-bold text-xl">
        {{ comentario.comment.author.nombre_completo.split("|")[0] }}
        <span class="font-light has-text-grey text-sm ml-2">
          {{ comentario.comment.updated_at | moment("DD-MM-YY h:mm a") }}</span
        >
      </p>
      <p class="mt-1" v-html="comentario.comment.texto" />
      <transition-group class="w-full" name="fadeInOut" mode="out-in">
        <div
          v-for="comentario in comentario.comment.child_comments"
          :key="comentario.id"
        >
          <div
            class="columns has-background-grey-lighter has-border-radius mt-3 mb-2"
          >
            <div class="column is-1 text-center p-2 " v-if="!small">
              <icon-initials
                :nombre="comentario.author.nombre_completo.split('|')[0]"
              />
            </div>
            <div
              class="column is-11 p-2"
              :class="{ 'is-11': !small, 'is-12': small }"
            >
              <p class="font-bold">
                {{ comentario.author.nombre_completo.split("|")[0] }}
                <span
                  class="font-light has-text-grey text-sm ml-2 block md:inline"
                >
                  {{ comentario.updated_at | moment("DD-MM-YY h:mm a") }}
                </span>
              </p>
              <p class="ml-2" v-html="comentario.texto" />
            </div>
          </div>
        </div>
      </transition-group>
      <b-button
        type="is-light"
        class="has-text-grey-light mt-4"
        icon-left="reply"
        @click="toggleComment"
        v-if="!responder"
      >
        Responder
      </b-button>
      <comentario-input
        :personas="personas"
        :taggedpersonas="taggedpersonas"
        @comentar="prepararComentario"
        v-if="responder"
        :white="true"
        class="mt-4 -mx-3"
      />
    </div>
  </div>
</template>
<script>
import IconInitials from "@/components/generals/IconInitials";
import ComentarioInput from "@/components/comentarios/ComentarioInput";
export default {
  name: "ComentarioCard",
  props: {
    comentario: Object,
    personas: Array,
    small: {
      type: Boolean,
      default: false
    },
    noBg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      responder: false,
      taggedpersonas: [],
      checked: false
    };
  },
  components: {
    IconInitials,
    ComentarioInput
  },
  methods: {
    toggleComment() {
      this.responder = true;
    },
    prepararComentario(data) {
      data.parent_id =
        this.comentario.comments_id == null
          ? this.comentario.id
          : this.comentario.comments_id;
      this.$emit("responder", data);
    }
  },
  mounted() {
    if (!this.comentario.hasOwnProperty("comment")) {
      let comentario = JSON.parse(JSON.stringify(this.comentario));
      this.comentario.comment = comentario;
      this.checked = true;
    } else {
      this.checked = true;
    }
  }
};
</script>
